<template>
  <div id="messages-chat">
    <TopMenu
      :title="title"
      :customBack="
        () => {
          $router.push('/hotel/' + id)
        }
      "
      bgColor="#f9f9f9"
    />
    <Loading v-if="messages == null || ws == null" />
    <div class="messages-wrapper">
      <div class="messages" ref="messages">
        <p class="no-messages" v-if="messages != null && messages.length < 1">
          {{ $t('messages.noMessages') }}
        </p>

        <div
          class="message"
          v-for="(msg, id) in messages || []"
          :key="id"
          :class="msg.msgType.toLowerCase().replace('_', '-')"
        >
          <div class="bubble" v-linkified>{{ msg.msg }}</div>
          <div class="time">{{ getTime(msg.time) }}</div>
        </div>
      </div>
    </div>

    <div class="textbox">
      <div class="input-wrapper">
        <input
          type="text"
          :placeholder="$t('messages.placeholder')"
          v-model="text"
          @keydown="
            e => {
              e.key === 'Enter' ? send() : ''
            }
          "
          autofocus
        />
      </div>
      <md-icon @click.native="send">send</md-icon>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import linkify from 'vue-linkify'
import TopMenu from '@/components/TopMenu'

Vue.directive('linkified', linkify)

export default {
  props: ['id'],
  components: { TopMenu },
  data() {
    return {
      messages: null,
      text: '',
      ws: null,
      reconnectTimeout: null,
      user: {},
      dialog: null,
    }
  },
  beforeDestroy() {
    if (this.reconnectTimeout) clearInterval(this.reconnectTimeout)
    if (this.ws) this.ws.close()
    this.ws = null
  },
  computed: {
    title() {
      return this.dialog?.propertyName ?? ''
    },
  },
  mounted() {
    this.fetchUser()
    this.fetchDialog()
    this.connect()
  },
  methods: {
    connect() {
      this.ws = new WebSocket(
        process.env.VUE_APP_CHAT_URL + '/' + localStorage.token
      )
      this.ws.onmessage = e => {
        let msg = JSON.parse(e.data)
        console.log('[Chat] Got message', e)
        if (msg.propertyId == undefined || msg.propertyId == this.id)
          this.messages.push(msg)

        if (this.dialog) this.markAsRead()
        else this.fetchDialog()

        this.scrollToBottom()
      }
      this.ws.onopen = e => {
        console.log('[Chat] WebSocket opened', e)
        this.fetchMessages()
      }
      this.ws.onclose = e => {
        console.log('[Chat] WebSocket closed', e)
        this.ws = null
        if (this.reconnectTimeout) clearInterval(this.reconnectTimeout)
        this.reconnectTimeout = setTimeout(() => this.connect(), 3000)
      }
      this.ws.onerror = e => {
        console.log('[Chat] WebSocket error', e)
        this.ws = null
        if (this.reconnectTimeout) clearInterval(this.reconnectTimeout)
        this.reconnectTimeout = setTimeout(() => this.connect(), 3000)
      }
    },
    fetchUser() {
      this.$api.get('user').then(res => {
        this.user = res.data.data
      })
    },
    fetchMessages() {
      this.$api.get(`user/messages/${this.id}`).then(res => {
        this.messages = res.data.data.sort((a, b) => {
          if (a.time < b.time) return -1
          if (a.time > b.time) return 1
          return 0
        })
        this.scrollToBottom()
      })
    },
    fetchDialog() {
      this.$api.get('user/messages').then(res => {
        res.data.data.forEach(dialog => {
          if (dialog.propertyId == this.id) {
            this.dialog = dialog
            this.markAsRead()
          }
        })
      })
    },
    markAsRead() {
      this.$api.post(`user/messages/${this.dialog.dialogId}`).then(() => {
        this.$events.emit('check-bell')
      })
    },
    send() {
      let text = this.text.trim()

      if (text != '') {
        this.ws.send(
          JSON.stringify({
            msg: text,
            time: new Date().normalize(),
            token: localStorage.token,
            propertyId: this.id,
            userId: this.user.userId,
            msgType: 'CHAT_OUTGOING',
          })
        )
      }

      this.text = ''
    },
    getTime(date) {
      let time = new Date(date).normalize(true)
      return [
        time.toLocaleString(this.$t('datetimeFormat'), { day: 'numeric' }),
        time.toLocaleString(this.$t('datetimeFormat'), { month: 'short' }),
        time.toLocaleString(this.$t('datetimeFormat'), {
          hour: 'numeric',
          minute: 'numeric',
        }),
      ].join(' ')
    },
    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.messages)
          this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
      })
    },
  },
}
</script>

<style lang="scss">
$textbox-height: 50px;
$send-width: 42px;

#messages-chat {
  .messages-wrapper {
    display: flex;
    flex-direction: column-reverse;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    bottom: $textbox-height;

    .messages {
      overflow-y: scroll;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 25px;

      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;

      .no-messages {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
      }

      .message {
        width: 100%;
        display: flex;
        margin-bottom: 15px;
        word-wrap: break-word;

        .bubble {
          display: inline-block;
          padding: 8px;
          border-radius: 16px;
          max-width: 50%;

          .linkified {
            color: inherit;
            text-decoration: underline;
          }
        }
        .time {
          align-self: flex-end;
          display: inline-block;
          font-size: 13px;
          color: rgb(148, 148, 148);
        }

        &.chat-outgoing {
          flex-direction: row-reverse;
          .bubble {
            background-color: #5f5f5f;
            color: white;
            margin-left: 5px;
          }
        }
        &.chat-income {
          .bubble {
            background-color: rgb(221, 221, 221);
            color: rgb(51, 51, 51);
            margin-right: 5px;
          }
        }
        &.promo {
          .bubble {
            background-color: #73cb77;
            color: white;
            margin-right: 5px;
          }
        }
      }
    }
  }
  .textbox {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: $textbox-height;
    background-color: white;
    border-top: #e8eceb 1px solid;

    .input-wrapper {
      position: absolute;
      top: 0;
      left: 10px;
      right: $send-width + 5px;
      bottom: 0;

      input {
        width: 100%;
        height: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        font-size: 18px;
      }
    }
    .md-icon {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 5px;
      width: $send-width;
      cursor: pointer;
      transition: 0.2s all;

      &:hover {
        color: rgb(187, 187, 187);
      }
    }
  }
}
</style>
